"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var ChartType = { Pie: 1, Column: 2, Bar: 3, Geo: 4 };
google.charts.load('current', { packages: ['corechart', 'bar', 'geochart'] });
function fetchChart(requestData, dataURL, chartDataSource, tabs, chartDiv, chartType, defaultOptions, options) {
    try {
        $('#' + chartDiv).css('background-image', 'none');
        if (!options)
            options = {};
        if (defaultOptions)
            options = __assign(__assign({}, defaultOptions), options);
        requestData.chart = chartDataSource;
        $.ajax({
            type: "POST",
            url: dataURL,
            data: JSON.stringify(requestData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (chartData) {
                if (chartData && chartData.chart) {
                    var data = google.visualization.arrayToDataTable(chartData.chart);
                    if ($('#' + chartDiv).is(':visible')) {
                        var chartElement = document.getElementById(chartDiv);
                        if (chartType == ChartType.Pie) {
                            var pieChart = new google.visualization.PieChart(chartElement);
                            pieChart.draw(data, options);
                        }
                        else if (chartType == ChartType.Bar) {
                            var barChart = new google.visualization.BarChart(chartElement);
                            barChart.draw(data, options);
                        }
                        else if (chartType == ChartType.Geo) {
                            var geoChart = new google.visualization.GeoChart(chartElement);
                            geoChart.draw(data, options);
                        }
                        else {
                            var columnChart = new google.visualization.ColumnChart(chartElement);
                            columnChart.draw(data, options);
                        }
                    }
                    attachChartTabEvent(tabs, chartDiv, chartType, data, options);
                }
            }
        });
    }
    catch (e) {
        console.log('Error: ' + e);
    }
}
function attachChartTabEvent(tabs, chartDiv, chartType, data, options) {
    if (!tabs)
        return;
    var targets = [];
    $.each(tabs, function (_index, value) {
        targets.push($('#' + value));
        targets.push($('#' + value).parent());
    });
    var delay = 400;
    $.each(targets, function (_index, value) {
        value.on('click', function (_e) {
            setTimeout(function () {
                try {
                    var chartElement = document.getElementById(chartDiv);
                    if (chartType == ChartType.Pie) {
                        var pieChart = new google.visualization.PieChart(chartElement);
                        pieChart.draw(data, options);
                    }
                    else if (chartType == ChartType.Bar) {
                        var barChart = new google.visualization.BarChart(chartElement);
                        barChart.draw(data, options);
                    }
                    else if (chartType == ChartType.Geo) {
                        var geoChart = new google.visualization.GeoChart(chartElement);
                        geoChart.draw(data, options);
                    }
                    else {
                        var columnChart = new google.visualization.ColumnChart(chartElement);
                        columnChart.draw(data, options);
                    }
                }
                catch (e) {
                    console.log('Error: ' + e);
                }
            }, delay); // NOTE: Important for redrawing charts
        });
    });
}
